import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["mainPartial", "patientPartial", "medicalHistoryPartial", "button", "pForm", "pField"]

  connect(){
    //this.mostrarOcultarElementos();
  }

    mostrarOcultarElementos() {
    this.mainPartialTarget.classList.add("hidden");
    this.patientPartialTarget.classList.add("hidden");
    const elementos = document.getElementsByClassName("question");
    const titleQuestions = document.getElementById("titleQuestions");

    // Convertir la colección HTMLCollection a un array para poder usar forEach
    const elementosArray = Array.from(elementos);
    this.patientToQuestionaire();
    elementosArray[0].classList.add("hidden");
    elementosArray[0].classList.remove("block");
    elementosArray.forEach((elemento, index) => {
      this.disableNextButtons(elemento);
        if (index === 0) {
        titleQuestions.classList.remove("hidden");
        elemento.classList.add("block");
        elemento.classList.remove("hidden");
          
      } else {
        elemento.classList.add("hidden");
        elemento.classList.remove("block");
        titleQuestions.classList.add("hidden");
      }
    });

    //Remover data-action de la ultima pregunta
    let lastQuestion = elementosArray[elementosArray.length - 1];
    let nextButton = lastQuestion.querySelector(".next");
    let submitButton = lastQuestion.querySelector(".submit");
    nextButton.removeAttribute("data-action");
    nextButton.classList.add("hidden");
    submitButton.classList.remove("hidden");

    //remover el data-action de la primera pregunta
    let firstQuestion = elementosArray[0];
    let returnButton = firstQuestion.querySelector(".return");
    returnButton.removeAttribute("data-action");
    returnButton.setAttribute("data-action", "click->questionaires#showPatient");
  }

  siguiente() {
    const currentIndex = this.currentIndex();
    const elementos = document.getElementsByClassName("question");
    const elementosArray = Array.from(elementos);

    elementosArray.forEach((elemento, index) => {
      if (index === currentIndex) {
        elemento.classList.add("hidden");
        elemento.classList.remove("block");
      } else if (index === (currentIndex + 1) % elementosArray.length) {
        elemento.classList.add("block");
        elemento.classList.remove("hidden");
      } else {
        elemento.classList.add("hidden");
        elemento.classList.remove("block");
      }
    });
  }

  anterior() {
    const currentIndex = this.currentIndex();
    const elementos = document.getElementsByClassName("question");
    const elementosArray = Array.from(elementos);
    const previousIndex = (currentIndex - 1 + elementosArray.length) % elementosArray.length;

    elementosArray.forEach((elemento, index) => {
      if (index === currentIndex) {
        elemento.classList.add("hidden");
        elemento.classList.remove("block");
      } else if (index === previousIndex) {
        elemento.classList.add("block");
        elemento.classList.remove("hidden");
      } else {
        elemento.classList.add("hidden");
        elemento.classList.remove("block");
      }
    });
  }

  currentIndex() {
    const elementos = document.getElementsByClassName("question");
    const elementosArray = Array.from(elementos);
    return elementosArray.findIndex(elemento => !elemento.classList.contains("hidden"));
  }

  showMain(){
    this.mainPartialTarget.classList.remove("hidden");
    this.patientPartialTarget.classList.add("hidden");
  }

  showPatient(){
    const elementos = document.getElementsByClassName("question");
    const elementosArray = Array.from(elementos);
    elementosArray[0].classList.add("hidden");
    elementosArray[0].classList.remove("block");
    this.mainPartialTarget.classList.add("hidden");
    this.patientPartialTarget.classList.remove("hidden");
  }

  patientToQuestionaire(){
    // Corroborar de que el URL tiene un paciente
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    // En caso de que si, que ejecute el siguiente código
    if (searchParams.has('id')) {
      // Obtener los valores del formulario de pacientes
      const formData = new FormData(this.pFormTarget);
      const json = Object.fromEntries(formData);
      
      // Obtener los datos por individual
      const name = formData.get("patient[name]");
      const gender = json["patient[gender]"];
      const birthdate = formData.get("patient[birthdate]");
      const email = formData.get("patient[email]");
      const mobile_phone = formData.get("patient[mobile_phone]");
      const country_code_id = formData.get("patient[country_code_id]");

      // Crear un objeto con la data
      const patient_info = {
        name: name,
        gender: gender,
        birthdate: birthdate,
        email: email,
        mobile_phone: mobile_phone,
        country_code_id: country_code_id
      }

      // Poner el objeto dentro del formulario que sera enviado al controlador
      this.pFieldTarget.value = JSON.stringify(patient_info);
    }
  }

  disableButtons(elemento) {
    console.log("desahabilitado");    
    const nextButton = elemento.querySelector('.next');
    const submitButton = elemento.querySelector('.submit');
    const returnButton = elemento.querySelector('.return');
    nextButton.disabled = true;
    submitButton.disabled = true;
    returnButton.disabled = true;  
  }

  enabledButtons(elemento) {
    console.log("habilitado");
    const nextButton = elemento.querySelector('.next');
    const submitButton = elemento.querySelector('.submit');
    const returnButton = elemento.querySelector('.return');
    nextButton.disabled = false;
    submitButton.disabled = false;
    returnButton.disabled = false;  
  }

  disableNextButtons(elemento){
    const queryResponse = elemento.querySelector('.questionResponse');
    queryResponse.addEventListener('input', (event) => {
        if (queryResponse.value.trim() === "") {
            this.disableButtons(elemento);
        } else {
            this.enabledButtons(elemento);
        }
    });
  }
}
