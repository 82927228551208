import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "query", "results", "patient_id", "name", "parient", "email","gender", "mobile_phone", "apiToken", "gender_radio", "birthdate", "ccInput", "dropdown_button"]
  static values = { url: String }
  static outlets = [ "search-results" ]

  connect() {
    this.verifyMobilePhone();
    this.mobile_phoneTarget.addEventListener('input', (e) => {
        this.formatPhoneNumber(e.target);
      });
    this.mobile_phoneTarget.addEventListener('change', (e) => {
      this.formatPhoneNumber(e.target);
    });
    }

  disconnect() {
    this.reset()
  }

  fetchResults() {

    if(this.query == "") {
      this.reset()
      this.patient_idTarget.value = ""
      return
    }

    if(this.query == this.previousQuery) {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.urlValue)
    url.searchParams.append("query", this.query)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {})
  }

  console_hello({ params: {id, name, email, parient, phone, gender, birthdate, countrycodeid}}){
    let searchResult = document.getElementById("search-results")
    this.queryTarget.value = name
    searchResult.classList.add("hidden")
    this.patient_idTarget.value = id;
    this.nameTarget && (this.nameTarget.value = name);
    // this.nameTarget.setAttribute('readonly', true);
    this.parientTarget && (this.parientTarget.value = parient);
    // this.parientTarget.setAttribute('readonly', true);
    this.emailTarget && (this.emailTarget.value = email);
    // this.emailTarget.setAttribute('readonly', true);
    this.mobile_phoneTarget && (this.mobile_phoneTarget.value = phone);
    this.ccInputTarget && (this.ccInputTarget.value = countrycodeid);
    this.ccInputTarget && this.verifyCountryCode();
    this.birthdateTarget && (this.birthdateTarget.value = birthdate);
    // this.mobile_phoneTarget.setAttribute('readonly', true);

    const matchingRadioButton = this.gender_radioTargets.find(radioButton => radioButton.nextElementSibling.textContent.toLowerCase() === gender.toLowerCase());
    
    if (matchingRadioButton) {
      matchingRadioButton.click();
    }

    this.formatPhoneNumber(this.mobile_phoneTarget);
  }

  navigateResults(event) {
    if(this.hasSearchResultsOutlet) {
      this.searchResultsOutlet.navigateResults(event)
    }
  }

  // private

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null

    // this.nameTarget.removeAttribute('readonly');
    // this.parientTarget.removeAttribute('readonly');
    // this.emailTarget.removeAttribute('readonly');
    // this.mobile_phoneTarget.removeAttribute('readonly');
    // this.genderTarget.removeAttribute('readonly');
  }

  formatPhoneNumber(input) {
    const value = input.value.replace(/\D/g, '');
    if (value.length <= 3) {
      input.value = value;
    } else if (value.length <= 7) {
      input.value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      input.value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.queryTarget.value
  }

  verifyPatient(){
    const urlParams = new URLSearchParams(window.location.search);
    const patientId = urlParams.get("patient");
    if (patientId) {
      this.getPatient(patientId);
    }
  }

  async getPatient(id){
    let token = this.apiTokenTarget.value;
    try {
      const response = await fetch('/api/v1/patients/' + id, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      const data = await response.json();
      const patient = data.data;
      this.patient_idTarget.value = patient.id;
      this.nameTarget.value = patient.name;
      this.parientTarget.value = patient.parient;
      this.emailTarget.value = patient.email;
      this.gender_radioTargets.forEach(radio => {
        radio.checked = radio.value === patient.gender;
      });
      this.mobile_phoneTarget.value = patient.mobile_phone;
      this.ccInputTarget.value = patient.country_code_id;
      this.ccInputTarget && this.verifyCountryCode();
      this.birthdateTarget.value = patient.birthdate;
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  verifyMobilePhone(){
    if(!this.mobile_phoneTarget.value == ""){
      this.verifyCountryCode();
    }
  }

  verifyCountryCode(){
    let code = this.ccInputTarget.value;
    this.changeDefaultCode(code);
  }

  changeDefaultCode(code){
    let div = this.dropdown_buttonTarget
    div.innerHTML = "";
    if(code > 1){
      div.innerHTML = `
        <img src="/assets/flag-usa-4b5f4fa8191b0ed9d2f8bf534ee547cd7763f3bf396ccea8b21706ebab871cb9.svg" alt="Estados Unidos/Canada" class="h-3.5 w-3.5 mr-2"></img><p class="text-gray-600 dark:text-gray-300">+1</p>`;
    }else{
      div.innerHTML = `
      <img src="/assets/flag-mexico-0fc2a0e8c81b1bea9203ed0e067a29cc74eb32f1a5b8a3216ba9a103fda9218d.svg" alt="México" class="h-3.5 w-3.5 mr-2"></img><p class="text-gray-600 dark:text-gray-300">+52</p>`;
    }
  }
}
