import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ "f_prescription", "v_search", "v_items", "b_next", "b_back", "step1", "step2", "step1Text", "step2Text", "search_results", "input_search" ];

    connect() {
        this.input_searchTarget.addEventListener('input', this.search.bind(this));
    }

    async step_change({ params: {id}}){
        switch(id){
            case 1:
                this.v_itemsTarget.classList.remove("hidden");
                this.v_searchTarget.classList.add("hidden");
                this.step1Target.classList.remove("bg-purple-700", "text-white");
                this.step1Target.classList.add("text-gray-500");
                this.step2Target.classList.add("bg-purple-700", "text-white");
                this.step2Target.classList.remove("text-gray-500");
                this.step1TextTarget.classList.add("text-gray-600");
                this.step1TextTarget.classList.remove("font-medium", "text-gray-800");
                this.step2TextTarget.classList.add("font-medium", "text-gray-800");
                this.step2TextTarget.classList.remove("text-gray-600");
            break;

            case 2:
                this.v_itemsTarget.classList.add("hidden");
                this.v_searchTarget.classList.remove("hidden");
                this.step1Target.classList.add("bg-purple-700", "text-white");
                this.step1Target.classList.remove("text-gray-500");
                this.step2Target.classList.remove("bg-purple-700", "text-white");
                this.step2Target.classList.add("text-gray-500");
                this.step1TextTarget.classList.remove("text-gray-600");
                this.step1TextTarget.classList.add("font-medium", "text-gray-800");
                this.step2TextTarget.classList.remove("font-medium", "text-gray-800");
                this.step2TextTarget.classList.add("text-gray-600");
            break;
        }
    }

    async search(event) {
      const searchQuery =  event.target.value
      try {

        const response = await fetch('/api/v1/prescriptions/find_patients?' + new URLSearchParams({
            search: searchQuery
        }))
        const data = await response.json();

        this.search_resultsTarget.innerHTML = '';

        data.data.forEach(result => {
            const mainDiv = document.createElement('div');
            mainDiv.classList.add('bg-white', 'py-4', 'flex', 'flex-col', 'sm:flex-row', 'items-start', 'sm:items-center', 'justify-between', 'px-5', 'sm:px-10', 'shadow', 'rounded', "mt-2");

            const leftDiv = document.createElement('div');
            leftDiv.classList.add('flex', 'items-center', 'mb-4', 'sm:mb-0', 'md:mb-0', 'lg:mb-0', 'xl:mb-0');

            const nameDiv = document.createElement('div');
            const nameHeading = document.createElement('h2');
            nameHeading.setAttribute('tabindex', '0');
            nameHeading.classList.add('focus:outline-none', 'text-gray-800', 'text-sm', 'font-bold');
            nameHeading.textContent = result.name;

            const viewMobile = document.createElement('div');
            const viewEmail = document.createElement('div');
            viewMobile.classList.add('focus:outline-none', 'focus:underline', 'focus:text-black', 'font-normal', 'text-xs', 'text-gray-600');
            viewEmail.classList.add('focus:outline-none', 'focus:underline', 'focus:text-black', 'font-normal', 'text-xs', 'text-gray-600');
            viewMobile.textContent = result.mobile_phone;
            viewEmail.textContent = result.email;

            nameDiv.appendChild(nameHeading);
            nameDiv.appendChild(viewEmail);
            nameDiv.appendChild(viewMobile);
            leftDiv.appendChild(nameDiv);

            const rightDiv = document.createElement('div');
            const contactButton = document.createElement('div');
            contactButton.setAttribute('data-action', 'click->prescriptions#store_patient_id');
            contactButton.setAttribute('data-prescriptions-id-param', result.id);
            contactButton.classList.add('focus:ring-2', 'focus:ring-offset-2', 'focus:ring-indigo-500', 'focus:bg-indigo-500', 'hover:bg-indigo-500', 'ml-2', 'sm:ml-3', 'font-normal', 'focus:outline-none', 'bg-indigo-700', 'transition', 'duration-150', 'ease-in-out', 'rounded', 'text-white', 'px-6', 'py-2', 'text-sm', 'cursor-pointer');
            contactButton.textContent = 'Seleccionar';

            rightDiv.appendChild(contactButton);

            mainDiv.appendChild(leftDiv);
            mainDiv.appendChild(rightDiv);

            this.search_resultsTarget.appendChild(mainDiv);

        });
      } catch (error) {
        console.error('Error al obtener los resultados de autocompletado:', error);
      }
    }

    store_patient_id({params: {id}}) {
        this.step_change({params: {id:1}});
        localStorage.setItem("patient_id", id);
    }

    create_json_items(){

    }


}
