import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "currency", "input" ]
    static values = { token: String, id: Number, amount: Number}

    connect() {
        this.events()
    }

    events(){
        const currencyChangeHandler = () => {
          this.currency_change();
        };
        this.currencyTarget.addEventListener("input", currencyChangeHandler);
        this.currencyTarget.addEventListener("change", currencyChangeHandler);
        
    }
    async currency_change(){
        
        const token = this.tokenValue

        const body = {
                account_currency_id: this.idValue,
                amount: this.amountValue,
                currency_id: this.currencyTarget.value
            };
        fetch(`/api/v1/currencies/change_rate`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
          })
          .then((response) => response.text())
          .then(data => {
            this.inputTarget.value = JSON.parse(data).results;
          })
          .catch(error => {
            console.error(error);
          });
    }
}