import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "step1","step2","step3","step4",
    "doctor_form","patient_form","calendar_form","grid_form",
    "sms_reminder","email_reminder","div"];

    connect(){

    }

    async step_change({ params: {id, disable}}){
        if(disable == false)
         {
          return
         }
        switch(id){
            case 1:
                this.step1Target.classList.remove("text-gray-500", "border-gray-500");
                this.step1Target.classList.add('leading-none', 'text-center', 'bg-blue-600', 'text-white');

                this.step2Target.classList.add("text-gray-500", "border-gray-500", "border-gray-500");
                this.step2Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step3Target.classList.add("text-gray-500", "border-gray-500");
                this.step3Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step4Target.classList.add("text-gray-500", "border-gray-500");
                this.step4Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.patient_formTarget.classList.remove("hidden");
                this.calendar_formTarget.classList.add("hidden");
                this.doctor_formTarget.classList.add("hidden");
                this.grid_formTarget.classList.add("hidden");

            break;
            case 2:
                this.step1Target.classList.add("text-gray-500", "border-gray-500");
                this.step1Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step2Target.classList.remove("text-gray-500", "border-gray-500");
                this.step2Target.classList.add('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step3Target.classList.add("text-gray-500", "border-gray-500");
                this.step3Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step4Target.classList.add("text-gray-500", "border-gray-500");
                this.step4Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.patient_formTarget.classList.add("hidden");
                this.doctor_formTarget.classList.remove("hidden");
                this.calendar_formTarget.classList.add("hidden");
                this.grid_formTarget.classList.add("hidden");

            break;
            case 3:
                this.step1Target.classList.add("text-gray-500", "border-gray-500");
                this.step1Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step2Target.classList.add("text-gray-500", "border-gray-500");
                this.step2Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step3Target.classList.remove("text-gray-500", "border-gray-500");
                this.step3Target.classList.add('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step4Target.classList.add("text-gray-500", "border-gray-500");
                this.step4Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.patient_formTarget.classList.add("hidden");
                this.doctor_formTarget.classList.add("hidden");
                this.calendar_formTarget.classList.remove("hidden");
                this.grid_formTarget.classList.add("hidden");

            break;
            case 4:
                this.step1Target.classList.add("text-gray-500", "border-gray-500");
                this.step1Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step2Target.classList.add("text-gray-500", "border-gray-500");
                this.step2Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step3Target.classList.add("text-gray-500", "border-gray-500");
                this.step3Target.classList.remove('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.step4Target.classList.remove("text-gray-500", "border-gray-500");
                this.step4Target.classList.add('leading-none', 'text-center', 'bg-blue-600', 'text-white', 'border-blue-600');

                this.patient_formTarget.classList.add("hidden");
                this.doctor_formTarget.classList.add("hidden");
                this.calendar_formTarget.classList.add("hidden");
                this.grid_formTarget.classList.remove("hidden");
            break;
        }
    }
}
