import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    static targets = [ "field", "text"];
    static values = { text: String }

    connect() {
       
        const fileInput = this.fieldTarget;
        const fileInputText = this.textTarget;
        const text = this.textValue;
        
        fileInput.addEventListener('change', function() {
        
            const fileName = this.value.split(/(\\|\/)/g).pop(); // Get the file name without path
            fileInputText.textContent = fileName || text;
        });
    }
}