import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "date", "doctor","appointments", "appointmentsEach", "pagyEach", "searchButton" ]

    connect(){
        this.events()
        this.loadAppointments()
    }

    events(){
        this.doctorTarget.addEventListener("change", this.loadAppointments.bind(this))
        this.dateTarget.addEventListener("change", this.loadAppointments.bind(this))
        this.searchButtonTarget.addEventListener("click", this.showEach.bind(this))
    }

    async loadAppointments(){
        this.appointmentsTarget.classList.remove("hidden");
        this.appointmentsEachTarget.classList.add("hidden");
        this.pagyEachTarget.classList.add("hidden");
        let doctor = this.doctorTarget.value
        let date = this.dateTarget.value
        fetch(`/appointments/user_date_appointments?date=${date}&id=${doctor}`, {
            headers: { accept: 'application/json'}
            }).then((response) => response.text())
            .then(data => {
                this.appointmentsTarget.innerHTML = ""
                JSON.parse(data).results.appointments.forEach(appointment => {
                    const tr = document.createElement('tr')
                    tr.classList.add('text-xs', 'bg-white', 'dark:bg-[#272626]')
                    const tdname = document.createElement('td')
                    tdname.classList.add('py-5', 'px-6', 'font-medium')
                    tr.appendChild(tdname)
                    const a = document.createElement('a')
                    a.classList.add('inline-flex', 'items-center', 'font-medium', 'text-blue-600', 'dark:text-blue-500', 'hover:underline')
                    a.setAttribute('href', `/appointments/${appointment.id}`)
                    a.innerHTML = `
                            ${appointment.patient}
                            <svg aria-hidden="true" class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd">
                                </path>
                            </svg>`
                    tdname.appendChild(a)
                    const tdstart = document.createElement('td')
                    tdstart.classList.add('font-medium')
                    tdstart.innerHTML = `${appointment.start}`
                    tr.appendChild(tdstart)
                    const tdend = document.createElement('td')
                    tdend.classList.add('font-medium')
                    tdend.innerHTML = `${appointment.end}`
                    tr.appendChild(tdend)
                    const tdtype = document.createElement('td')
                    tdtype.classList.add('font-medium')
                    tdtype.innerHTML = `${appointment.appointment_type}`
                    tr.appendChild(tdtype)
                    const tdstatus = document.createElement('td')
                    tdstatus.innerHTML = `<span class="inline-block py-1 px-2 text-white bg-green-500 rounded-full">${appointment.status}</span>`
                    tr.appendChild(tdstatus)

                    this.appointmentsTarget.appendChild(tr)
                });
            });
    }

    showEach(){
        this.appointmentsEachTarget.classList.remove("hidden");
        this.pagyEachTarget.classList.remove("hidden");
        this.appointmentsTarget.classList.add("hidden");
    }
}
