import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "query", "results", "id","continue_md", "reason"]
  static values = { url: String }
  static outlets = [ "search-results" ]

  disconnect() {
    this.reset()
  }

  validate(){
    if(this.reasonTarget.value == "") {
      this.toggleContinue(false)   
    }else{
      if(this.query != "") {
        this.toggleContinue(true)
      }
    }
  }

  fetchResults() {
    if(this.query == "") {
      this.reset()
      this.toggleContinue(false)
      return
    }

    if(this.query == this.previousQuery) {
      return
    }
    this.previousQuery = this.query

    const url = new URL(this.urlValue)
    url.searchParams.append("query", this.query)

    this.abortPreviousFetchRequest()

    this.abortController = new AbortController()
    fetch(url, { signal: this.abortController.signal })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
      })
      .catch(() => {})
  }

  console_hello({ params: {id, name}}){
    let searchResult = document.getElementById("search-results-doctors")
    this.queryTarget.value = name
    searchResult.classList.add("hidden")
    this.idTarget.value = id
    if(this.reasonTarget.value != ""){ 
      this.toggleContinue(true)
    }
  }
  
  navigateResults(event) {
    if(this.hasSearchResultsOutlet) {
      this.searchResultsOutlet.navigateResults(event)
    }
  }

  toggleContinue(flag){
    if (flag){
      this.continue_mdTarget.setAttribute('data-start-disable-param',"true")
      this.continue_mdTarget.classList.remove("border-blue-200","bg-blue-200","cursor-not-allowed")
      this.continue_mdTarget.classList.add("border-blue-700","bg-blue-700","hover:bg-gray-600", "cursor-pointer")
    }else{
      this.continue_mdTarget.setAttribute('data-start-disable-param',"false")
      this.continue_mdTarget.classList.add("border-blue-200","bg-blue-200","cursor-not-allowed")
      this.continue_mdTarget.classList.remove("border-blue-700","bg-blue-700","hover:bg-gray-600", "cursor-pointer")
    }
  }

  // private

  reset() {
    this.resultsTarget.innerHTML = ""
    this.queryTarget.value = ""
    this.previousQuery = null
  }

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
  }

  get query() {
    return this.queryTarget.value
  }
}
