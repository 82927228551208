import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    static targets = ["options", "date","weekly_calendar","daily_calendar","fields","day"]
    static values = { hours: Array, token: String }

    connect() {
        this.events();
    }

    events() {
        this.optionsTarget.addEventListener("change", (event) => {
            this.change_calendar();
        })
        this.dateTarget.addEventListener("change", (event) => {
            this.change_fields();
        })
    }

    change_calendar(){
        this.dateTarget.classList.toggle("md:opacity-0");
        this.weekly_calendarTarget.classList.toggle("md:block");
        this.daily_calendarTarget.classList.toggle("md:hidden");
    }

    async change_fields(){
        const date = this.dateTarget.value;
        
        const day = new Date(date);
        day.setDate(day.getDate() + 1);
        const day_name = day.toLocaleDateString('es-MX', { weekday: 'long' });
        const day_number = day.toLocaleDateString('es-MX', { day: 'numeric' });

        const token = this.tokenValue;
        let remainder = 0;
        let color = "";
        this.dayTarget.innerHTML = this.capitalizeFirstWord(day_name)+" "+day_number;
        this.fieldsTarget.innerHTML = "";

        for (const hour of this.hoursValue) {
            try {
                const response = await fetch(`/api/v1/find_appointments?date=${date}&hour=${hour}`, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                    }
                });
            
                if (response.ok) {
                    const data = await response.json();
                
                    const div1 = document.createElement("div");
                    // Always add these classes
                    div1.classList.add("h-40", "flex", "flex-col");
                    
                    if(data.results.length > 0) {
                        data.results.forEach((result) => {
                            // Add classes conditionally based on your conditions
                            if (result.duration <= 60) {
                            div1.classList.add("border-b");
                            }
                            if (result.start_min == 30) {
                            div1.classList.add("justify-end");
                            }
                            if(result.start_min == 0){
                                color = this.randomBackgroundColorStyle();
                                remainder = result.duration;
                                const div2 = document.createElement("div");
                                div2.classList.add("px-2", "py-2", "w-full", color.bg_color, color.text_color);
                                // Add classes conditionally based on your conditions
                                if (remainder > 60) {
                                    div2.classList.add("rounded-t");
                                }else{
                                    div2.classList.add("rounded");
                                }
                                if(result.duration >= 60){
                                    div2.style.height = "100%";
                                }else if(result.duration <= 30){
                                    div2.style.height = `50%`;
                                }
                                
                                // div2.style.height = `${(result.duration <= 60 ? ((result.duration / 60) * 100).toString() + '%' : '100%')}`;
                                const p1 = document.createElement("p");
                                p1.classList.add("text-xs", "leading-3");
                                p1.innerHTML = `${result.start} - ${result.end}`;
                                const p2 = document.createElement("p");
                                p2.classList.add("text-xs", "font-semibold", "leading-3", "pt-2");
                                p2.innerHTML = `${result.patient.name}`;
                                const p3 = document.createElement("p");
                                p3.classList.add("text-xs", "leading-3", "pt-2");
                                p3.innerHTML = `${result.appointment_type}`;
                                div2.appendChild(p1);
                                div2.appendChild(p2);
                                div2.appendChild(p3);
                                div1.appendChild(div2);
                                this.fieldsTarget.appendChild(div1);
                                if(result.duration > 60){
                                    remainder = result.duration - 60;
                                }else{
                                    remainder = 0;
                                }
                            }
                            else if(result.start_min == 30){
                                if(remainder > 0){
                                    const div = document.createElement("div");
                                    div.classList.add("h-40", "border-b");
                                    const div2 = document.createElement("div");
                                    div2.classList.add("rounded-b", "px-2", "py-2", "w-full", color.bg_color, color.text_color);
                                    div2.style.height = `${((remainder / 60) * 100).toString()}%`;
                                    div.appendChild(div2);
                                    div1.appendChild(div);
                                    remainder = 0;
                                }
                                
                                color = this.randomBackgroundColorStyle();
                                remainder = result.duration;
                                const div2 = document.createElement("div");
                                div2.classList.add("rounded", "px-2", "py-2", "w-full", color.bg_color, color.text_color);
                                div2.style.height = `${(result.duration <= 30 ? ((result.duration / 60) * 100).toString() + '%' : '50%')}`;
                                const p1 = document.createElement("p");
                                p1.classList.add("text-xs", "leading-3");
                                p1.innerHTML = `${result.start} - ${result.end}`;
                                const p2 = document.createElement("p");
                                p2.classList.add("text-xs", "font-semibold", "leading-3", "pt-2");
                                p2.innerHTML = `${result.patient.name}`;
                                const p3 = document.createElement("p");
                                p3.classList.add("text-xs", "leading-3", "pt-2");
                                p3.innerHTML = `${result.appointment_type}`;
                                div2.appendChild(p1);
                                div2.appendChild(p2);
                                div2.appendChild(p3);
                                div1.appendChild(div2);
                                this.fieldsTarget.appendChild(div1);
                                if(result.duration > 30){
                                    remainder = result.duration - 30;
                                }else{
                                    remainder = 0;
                                }
                            }
                        })
                    }else{
                        if(remainder > 0 && remainder <= 60){
                            const div1 = document.createElement("div");
                            div1.classList.add("h-40", "border-b");
                            const div2 = document.createElement("div");
                            div2.classList.add("rounded-b", "px-2", "py-2", "w-full", color.bg_color, color.text_color);
                            div2.style.height = `${((remainder / 60) * 100).toString()}%`;
                            div1.appendChild(div2);
                            this.fieldsTarget.appendChild(div1);
                            remainder = 0;
                        }else if(remainder > 60){
                            const div1 = document.createElement("div");
                            div1.classList.add("h-40");
                            const div2 = document.createElement("div");
                            div2.classList.add("px-2", "py-2", "w-full", "h-full", color.bg_color, color.text_color);
                            div1.appendChild(div2);
                            this.fieldsTarget.appendChild(div1);
                            remainder = remainder - 60;
                        }else{
                            const div1 = document.createElement("div");
                            div1.classList.add("h-40", "border-b");
                            this.fieldsTarget.appendChild(div1);
                        }
                    }
                } else {
                    // Handle HTTP error here
                    console.error('HTTP error:', response.status);
                }
            } catch (error) {
                // Handle other errors here
                console.error('Error:', error);
            }
        }
    }

    capitalizeFirstWord(str) {
        // Split the string into an array of words
        const words = str.split(' ');
      
        // Capitalize the first word
        if (words.length > 0) {
          words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        }
      
        // Join the words back into a string and return
        return words.join(' ');
      }

      randomBackgroundColorStyle() {
        // Create an array of color objects with shades of 500 and text-color set to text-white
        const tailwindColors500 = [
          { name: "red-500", bg_color: "bg-red-500", text_color: "text-white" },
          { name: "blue-500", bg_color: "bg-blue-500", text_color: "text-white" },
          { name: "green-500", bg_color: "bg-green-500", text_color: "text-white" },
          { name: "yellow-500", bg_color: "bg-yellow-500", text_color: "text-white" },
          { name: "pink-500", bg_color: "bg-pink-500", text_color: "text-white" },
          { name: "purple-500", bg_color: "bg-purple-500", text_color: "text-white" },
          { name: "indigo-500", bg_color: "bg-indigo-500", text_color: "text-white" },
          { name: "teal-500", bg_color: "bg-teal-500", text_color: "text-white" },
          { name: "cyan-500", bg_color: "bg-cyan-500", text_color: "text-white" },
          // Add more colors as needed
        ];
      
        // Select a random color object from the array
        const randomColor = tailwindColors500[Math.floor(Math.random() * tailwindColors500.length)];
      
        

        // Return the background color and text color as a string
        return randomColor;
      }
}