import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["apiToken", "providerId", "providerSearch", "providerContainer", "dropdownQuery", "providerSearchContainer", "deleteProvider", "providerName", "providerBusiness"];

    connect(){
      this.verifyProvider();
      this.providerSearchTarget.addEventListener('input', this.search.bind(this));
    }

    async search(event) {
        let token = this.apiTokenTarget.value;
        const searchQuery =  event.target.value
        try {
          const response = await fetch('/api/v1/purchases/find_providers?' + new URLSearchParams({
              search: searchQuery
          }), {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token
            }
        });
        const data = await response.json();

        this.dropdownQueryTarget.innerHTML = '';
          data.data.forEach(result => {
            const mainA = document.createElement('a');
            mainA.classList.add("block", "p-2", "bg-white", "dark:bg-[#404040]", "hover:bg-gray-400", "dark:hover:bg-gray-400", "cursor-pointer", "dark:hover:text-gray-800", "transition");
            const labelProvider = document.createElement('p');
            labelProvider.classList.add("font-normal", "text-gray-700", "ml-3", "dark:text-gray-100");
            labelProvider.textContent = result.name;
            mainA.appendChild(labelProvider);
            mainA.addEventListener('click', ()=>{
                this.providerContainerTarget.classList.toggle('hidden');
                this.providerSearchContainerTarget.classList.toggle('hidden');
                this.providerIdTarget.value = result.id;
                this.setProvider(result);
            });
            this.dropdownQueryTarget.appendChild(mainA);
          });
        } catch (error) {
          console.error('Error al obtener los resultados de autocompletado:', error);
        }
    }

    setProvider(provider){
        this.providerNameTarget.textContent = provider.name;
        this.providerBusinessTarget.textContent = provider.business_name;
        this.deleteProviderTarget.addEventListener('click', ()=>{
            this.providerContainerTarget.classList.add('hidden');
            this.providerSearchContainerTarget.classList.remove('hidden');
            this.providerIdTarget.value = "";
        });
    }

    verifyProvider(){
      const urlParams = new URLSearchParams(window.location.search);
      const providerId = urlParams.get("provider");
      if (providerId) {
        this.getProvider(providerId);
      }
    }

    async getProvider(id){
      let token = this.apiTokenTarget.value;
      try {
        const response = await fetch('/api/v1/providers/' + id, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const data = await response.json();
        const provider = data.data;
        this.providerContainerTarget.classList.remove('hidden');
        this.providerSearchContainerTarget.classList.add('hidden');
        this.deleteProviderTarget.classList.add('hidden');
        this.providerNameTarget.textContent = provider.name;
        this.providerBusinessTarget.textContent = provider.business_name;
      } catch (error) {
        console.log('Error: ', error);
      }
    }
}
