import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "elements","navbars","sm_navbar"]


  connect() {
    
    this.events()
  }

  events() {
        this.navbarsTargets.forEach((element,index) => {
            element.addEventListener('click', () => {
                this.eventHandler(event,index)
            })
            }
        )
    }

  eventHandler(event, id) {
    this.elementsTargets.forEach((element,index) => {
        if (id == index){
            element.classList.remove("hidden")
            this.navbarsTargets[index].classList.remove("text-gray-600")
            this.navbarsTargets[index].classList.add("text-indigo-700")
            const nestedDiv = this.navbarsTargets[index].querySelector("div"); // Find the nested div element
            nestedDiv.classList.remove("hidden")
        }
        else{
            element.classList.add("hidden")
            this.navbarsTargets[index].classList.add("text-gray-600")
            this.navbarsTargets[index].classList.remove("text-indigo-700")
            const nestedDiv = this.navbarsTargets[index].querySelector("div"); // Find the nested div element
            nestedDiv.classList.add("hidden");
        }
    })
  }
}