import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "inventoryContainer", "checkboxManage" ];

    connect() {
        this.verifyManage();
        this.checkboxManageTarget.addEventListener('click', this.verifyManage.bind(this));
    }

    verifyManage(){
        let checkboxValue = this.checkboxManageTarget.checked;
        const inventoryContainer = this.inventoryContainerTarget;
        if(checkboxValue){
            inventoryContainer.classList.remove("hidden");
        }else{
            inventoryContainer.classList.add("hidden");
        }
    }
}
