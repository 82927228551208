import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "phone_number"]


    connect() {
        this.phone_numberTarget.addEventListener('input', (e) => {
            this.formatPhoneNumber(e.target);
        });
        this.phone_numberTarget.addEventListener('change', (e) => {
            this.formatPhoneNumber(e.target);
        });
    }

    formatPhoneNumber(input) {
        const value = input.value.replace(/\D/g, '');
        if (value.length <= 3) {
          input.value = value;
        } else if (value.length <= 7) {
          input.value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
        } else {
          input.value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }
    }
}