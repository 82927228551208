import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
    static targets = [ "calendarBody", "previousMonth", "nextMonth", "monthLabel",
                        "time","day","start_time","calendarp","div", "availability","continue", "proposed_date"];
    static values = { token: String }

    currentYear; // Declarar como propiedad
    currentMonth; // Declarar como propiedad
    date; // Declarar como propiedad
    startDayMonth; // Declarar como propiedad
    currentDiv; // Declarar como propiedad
    connect() {
        this.currentYear = new Date().getFullYear();
        this.currentMonth = new Date().getMonth();
        this.date = new Date();
        this.date.setMonth(this.currentMonth);
        this.date.setDate(1);
        this.startDayMonth = this.date.getDay();
        this.connectButtons();
        this.renderCalendar();
    }

    toggleCalendar() {
        this.divTarget.classList.toggle('hidden');
    }

    async renderCalendar() {

      const token = this.tokenValue
      fetch(`/api/v1/availability/days`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => response.text())
      .then(data => {
        const today = new Date();
        const todayDay = today.getDate();
        const todayMonth = today.getMonth();
        const todayYear = today.getFullYear();


        // Convert to Date objects
        const todayDate = this.parseDate(`${todayDay} ${todayMonth+1} ${todayYear}`);

        // Clear the calendar body
        this.calendarBodyTarget.innerHTML = '';
        //Transform numeric date to text and add it to month label
        const options = { year: 'numeric', month: 'long' };
        const formattedDate = this.date.toLocaleDateString('es-MX', options);
        this.monthLabelTarget.innerHTML = this.capitalizeFirstWord(formattedDate);
        // Get the number of days in the current month
        let daysInMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        //Create a table row to attach day div
        let tr = document.createElement('tr');
        //Create a counter to create a row after count 7 days
        let rowCounter = 0;
        //Variable to print days in the calendar
        let currentDayText = 1;
        //We need to attach days to print empty div
        daysInMonth = daysInMonth + this.startDayMonth;
        // Loop through each day of the month
        for (let day = 1; day <= daysInMonth; day++) {

            const currentDate = this.parseDate(`${currentDayText} ${this.currentMonth+1} ${this.currentYear}`);

            const currentFormatDate = new Date(this.currentYear, this.currentMonth, currentDayText);

            // Get the day of the week in number format (0 for Sunday, 1 for Monday, etc.)
            const dayOfWeekNumber = currentFormatDate.getDay();

            // Create a new row after every 7 days
            if (rowCounter % 7 == 0){
                this.calendarBodyTarget.appendChild(tr);
                tr = document.createElement('tr');
            }
            // Create a table cell for the day
            const cell = document.createElement('td');
            cell.classList.add('pt-6');
            // Create a div element with the day number
            const div = document.createElement('div');
            div.classList.add('flex', 'justify-center', 'w-full');
            //Check if the day on which the month starts is the same as the day on which the cycle begins
            if (this.startDayMonth < day){
                if (currentDate >= todayDate && JSON.parse(data).results.includes(dayOfWeekNumber) ) {
                    div.classList.add('text-gray-900', 'dark:text-gray-100', 'rounded-full', 'text-center','text-sm', 'cursor-pointer', 'p-2','hover:bg-blue-600', 'hover:text-white','transition', 'duration-500');
                    const specificDay = currentDayText
                    div.innerText = currentDayText;
                    // Add an event listener to the day div
                    div.addEventListener('click', () => {
                        if (window.location.pathname == '/appointment_change_requests/new'){
                            this.calendarAppointmentChangeRequest(div, specificDay);
                        }else{
                            this.goToDay(div, specificDay);
                        }
                    });
                } else {
                  div.classList.add('dark:text-gray-600', 'rounded-full','text-center','text-sm','cursor-not-allowed','text-gray-400','p-2');
                  div.innerText = currentDayText;
                }
                //div.appendChild(divC);
                currentDayText++;
            }
            // Append the day div to the table cell
            cell.appendChild(div);

            // Append the table cell to the calendar body
            tr.appendChild(cell);
            rowCounter++;
        }
        this.calendarBodyTarget.appendChild(tr);
      })
    }

    connectButtons(){
        this.previousMonthTarget.addEventListener('click', () => {
            // Handle the click event for the day
            this.goToPreviousMonth();
        });
        this.nextMonthTarget.addEventListener('click', () => {
            // Handle the click event for the day
            this.goToNextMonth();
        });
    }

    // Function to go to the previous month
    goToPreviousMonth() {
        this.currentMonth--;
        this.date.setMonth(this.currentMonth);
        this.startDayMonth = this.date.getDay();
        if (this.currentMonth < 0) {
            this.currentMonth = 11;
            this.currentYear--;
        }
        this.renderCalendar();
    }

    // Function to go to the next month
    goToNextMonth() {
        this.currentMonth++;
        this.date.setMonth(this.currentMonth);
        this.startDayMonth = this.date.getDay();
        if (this.currentMonth > 11) {
            this.currentMonth = 0;
            this.currentYear++;
        }
        this.renderCalendar();
    }

    parseDate(dateString) {
        const [day, month, year] = dateString.split(' ').map(Number);
        return new Date(year, month - 1, day);
    }

    // Function to click an specific day
    async goToDay(div , day) {
        this.timeTarget.classList.remove('hidden');

        if (this.currentDiv){
            this.currentDiv.classList.remove('bg-blue-600', 'text-white');
        }
        this.currentDiv = div;
        this.currentDiv.classList.add('bg-blue-600', 'text-white');

        const currentDate = `${day}-${this.currentMonth+1}-${this.currentYear}`;

        const newDate = `${this.currentYear}-${String(this.currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

        // Clear existing options
        this.availabilityTarget.innerHTML = '';

        // Convert the date to the specified time zone in the output string
        const date = new Date(newDate+'T12:00:00');

        const dayOfWeekText = date.toLocaleDateString('es-MX', { weekday: 'long' });

        const monthText = date.toLocaleDateString('es-MX', { month: 'long' });
        this.dayTarget.innerHTML = `${this.capitalizeFirstWord(dayOfWeekText)}, ${day} de ${this.capitalizeFirstWord(monthText)}`;
        this.calendarpTarget.innerHTML = `${this.capitalizeFirstWord(dayOfWeekText)}, ${day} de ${this.capitalizeFirstWord(monthText)}`;
        const id = document.getElementById('appointment_user_id').value;
        console.log(`/appointments/availability?date=${currentDate}&id=${id}`);
        // Fetch availability for the selected date
        fetch(`/appointments/availability?date=${currentDate}&id=${id}`, {
            headers: { accept: 'application/json'}
            }).then((response) => response.text())
            .then(data => {
                JSON.parse(data).results.availability.forEach((time) => {

                    // Extract the hour part from the time string and convert it to a number
                    let hour = parseInt(time.split(":")[0], 10);

                    // Create a variable to hold the AM/PM indication
                    let ampm = "";

                    // Perform the AM/PM conversion
                    if (hour >= 12) {
                        ampm = "PM";
                        if (hour > 12) {
                            hour = hour - 12;
                        }
                    } else {
                        ampm = "AM";
                    }

                    // Create a variable to hold the formatted time with AM/PM
                    const formattedTime = `${hour.toString().padStart(2, "0")}:${time.split(":")[1]} ${ampm}`;

                    const div = document.createElement('div');

                    div.classList.add('flex', 'items-center', 'mb-4');
                    // Create the radio button for
                    const radio = document.createElement('input');
                    radio.type = 'radio';
                    radio.value = `${currentDate} ${formattedTime}`;
                    radio.name = 'appointment[start]';
                    radio.id = `appointment_start_${formattedTime}`;
                    radio.required = 'required';
                    radio.classList.add('w-4', 'h-4', 'border-gray-300', 'focus:ring-2', 'focus:ring-blue-300','cursor-pointer');

                    const label = document.createElement('label');
                    label.textContent = formattedTime;
                    label.htmlFor = `appointment_start_${formattedTime}`;
                    label.classList.add('block', 'ml-2', 'text-sm', 'font-medium', 'text-gray-900','cursor-pointer');
                    // Add an event listener to the time div
                    radio.addEventListener('click', () => {
                        // Handle the click event for the day
                        this.start_timeTarget.value = `${currentDate} ${formattedTime}`;

                        fetch(`/doctors/${id}.json`, {
                            headers: { accept: 'application/json'}
                            }).then((response) => response.text())
                            .then(data => {
                                const doctor = JSON.parse(data)

                                const radioButtons = document.querySelectorAll('input[name="appointment[gender]"]');

                                // Iterate through radio buttons to find the checked one
                                let selectedValue = null;

                                radioButtons.forEach(radioButton => {
                                    if (radioButton.checked) {
                                        selectedValue = radioButton.value;
                                    }
                                });

                                const country_code_id = document.getElementById('appointment_country_code_id').value
                                let lada
                                if (country_code_id == 1){
                                    lada = '+52'
                                }
                                else if (country_code_id == 2){
                                    lada = '+1'
                                }

                                document.getElementById('doctor_name').innerText = doctor.name
                                document.getElementById('patient_name').innerText = document.getElementById('appointment_name').value
                                document.getElementById('patient_gender').innerText = selectedValue
                                document.getElementById('patient_email').innerText = document.getElementById('appointment_email').value
                                document.getElementById('patient_phone').innerText = `${lada} ${document.getElementById('appointment_mobile_phone').value}`
                                document.getElementById('patient_parient').innerText = document.getElementById('appointment_parient').value
                                document.getElementById('text_reason').innerText = document.getElementById('appointment_reason').value
                                document.getElementById('text_grid').innerText = `${this.dayTarget.innerText} a las ${formattedTime}.`

                                this.toggleContinue(true)
                            })
                    });
                    label.addEventListener('click', () => {
                      // Handle the click event for the day
                      this.start_timeTarget.value = `${currentDate} ${formattedTime}`;

                      fetch(`/doctors/${id}.json`, {
                          headers: { accept: 'application/json'}
                          }).then((response) => response.text())
                          .then(data => {
                            const doctor = JSON.parse(data)

                            const radioButtons = document.querySelectorAll('input[name="appointment[gender]"]');

                            // Iterate through radio buttons to find the checked one
                            let selectedValue = null;

                            radioButtons.forEach(radioButton => {
                                if (radioButton.checked) {
                                    selectedValue = radioButton.value;
                                }
                            });

                            const country_code_id = document.getElementById('appointment_country_code_id').value
                            let lada
                            if (country_code_id == 1){
                                lada = '+52'
                            }
                            else if (country_code_id == 2){
                                lada = '+1'
                            }

                            document.getElementById('doctor_name').innerText = doctor.name
                            document.getElementById('patient_name').innerText = document.getElementById('appointment_name').value
                            document.getElementById('patient_gender').innerText = selectedValue
                            document.getElementById('patient_email').innerText = document.getElementById('appointment_email').value
                            document.getElementById('patient_phone').innerText = `${lada} ${document.getElementById('appointment_mobile_phone').value}`
                            document.getElementById('patient_parient').innerText = document.getElementById('appointment_parient').value
                            document.getElementById('text_reason').innerText = document.getElementById('appointment_reason').value
                            document.getElementById('text_grid').innerText = `${this.dayTarget.innerText} a las ${formattedTime}.`

                            this.toggleContinue(true)
                            })
                    });
                    div.appendChild(radio);
                    div.appendChild(label);
                    this.availabilityTarget.appendChild(div);
                });
            });
    }

    async calendarAppointmentChangeRequest(div, day) {
        this.timeTarget.classList.remove('hidden');

        if (this.currentDiv) {
            this.currentDiv.classList.remove('bg-blue-600', 'text-white');
        }

        this.currentDiv = div;
        this.currentDiv.classList.add('bg-blue-600', 'text-white');

        const currentDate = `${day}-${this.currentMonth + 1}-${this.currentYear}`;
        const newDate = `${this.currentYear}-${String(this.currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        // Clear existing options
        this.availabilityTarget.innerHTML = '';

        const date = new Date(newDate);

        // Get the day of the week as text (e.g., "Sunday," "Monday," etc.)
        const dayOfWeekText = date.toLocaleDateString('es-MX', { weekday: 'long' });

        const monthText = date.toLocaleDateString('es-MX', { month: 'long' });
        this.dayTarget.innerHTML = `${this.capitalizeFirstWord(dayOfWeekText)}, ${day} de ${this.capitalizeFirstWord(monthText)}`;
        this.calendarpTarget.innerHTML = `${this.capitalizeFirstWord(dayOfWeekText)}, ${day} de ${this.capitalizeFirstWord(monthText)}`;
        const id = document.getElementById('appointment_user_id').value;
        // Fetch availability for the selected date
        fetch(`/appointments/availability?date=${currentDate}&id=${id}`, {
            headers: { accept: 'application/json' }
        })
        .then((response) => response.text())
        .then(data => {
            JSON.parse(data).results.availability.forEach((time) => {
                // Extract the hour part from the time string and convert it to a number
                let hour = parseInt(time.split(":")[0], 10);

                // Create a variable to hold the AM/PM indication
                let ampm = "";

                // Perform the AM/PM conversion
                if (hour >= 12) {
                    ampm = "PM";
                    if (hour > 12) {
                        hour = hour - 12;
                    }
                } else {
                    ampm = "AM";
                }

                // Create a variable to hold the formatted time with AM/PM
                const formattedTime = `${hour.toString().padStart(2, "0")}:${time.split(":")[1]} ${ampm}`;

                const div = document.createElement('div');

                div.classList.add('flex', 'items-center', 'mb-4');
                // Create the radio button for
                const radio = document.createElement('input');
                radio.type = 'radio';
                radio.value = `${currentDate} ${formattedTime}`;
                radio.name = 'appointment[start]';
                radio.id = `appointment_start_${formattedTime}`;
                radio.required = 'required';
                radio.classList.add('w-4', 'h-4', 'border-gray-300', 'focus:ring-2', 'focus:ring-blue-300', 'cursor-pointer');

                const label = document.createElement('label');
                label.textContent = formattedTime;
                label.htmlFor = `appointment_start_${formattedTime}`;
                label.classList.add('block', 'ml-2', 'text-sm', 'font-medium', 'text-gray-900', 'cursor-pointer');
                // Add an event listener to the time div
                radio.addEventListener('click', () => {
                    // Handle the click event for the day
                    // this.start_timeTarget.value = `${currentDate} ${formattedTime}`;
                    let dateString = `${currentDate} ${formattedTime}`;
                    let parts = dateString.split(/[- :]/);
                    let year = parseInt(parts[2], 10);
                    let month = parseInt(parts[1], 10) - 1; // months are zero-based
                    let day = parseInt(parts[0], 10);
                    let hour = parseInt(parts[3], 10) + (parts[4].toLowerCase() === "pm" ? 12 : 0); // adjust for PM
                    let minute = parseInt(parts[4], 10);
                    let datetime = new Date(year, month, day, hour, minute);
                    this.proposed_dateTarget.value = datetime;
                });
                div.appendChild(radio);
                div.appendChild(label);
                this.availabilityTarget.appendChild(div);
            });
        });
    }


    toggleContinue(flag){
      if (flag){
        this.continueTarget.setAttribute('data-start-disable-param',"true")
        this.continueTarget.classList.remove("border-blue-200","bg-blue-200","cursor-not-allowed")
        this.continueTarget.classList.add("border-blue-700","bg-blue-700", "cursor-pointer")
      }else{
        this.continueTarget.setAttribute('data-start-disable-param',"false")
        this.continueTarget.classList.add("border-blue-200","bg-blue-200","cursor-not-allowed")
        this.continueTarget.classList.remove("border-blue-700","bg-blue-700", "cursor-pointer")
      }
    }

    capitalizeFirstWord(str) {
        // Split the string into an array of words
        const words = str.split(' ');

        // Capitalize the first word
        if (words.length > 0) {
          words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        }

        // Join the words back into a string and return
        return words.join(' ');
      }

}

